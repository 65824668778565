import _ from 'lodash';
import moment from 'moment';

// Please avoid local module dependencies here (to avoid circular dependencies)

export function stopPropagationAndDefault(func, ...args) {
  return (e) => {
    e.preventDefault();
    e.stopPropagation();
    return func(...args);
  };
}

/**
 * { a: 1, ... } --> [ {key:"a", value: 1}, ... ]
 *
 * @param map
 */
export function map2KvList(map) {
  return _.map(map, (value, key) => ({ key, value }));
}

/**
 * [ {key:"a", value: 1}, ... ] --> { a: 1, ... }
 *
 * @param list
 */
export function kvList2Map(list, keyProp = 'key', valueProp = 'schema') {
  return _.chain(list)
    .filter(keyProp)
    .keyBy(keyProp)
    .mapValues(valueProp)
    .value();
}

/**
 * { a: {x: 1}, ... } --> [ {key:"a", x: 1}, ... ]
 *
 * @param map
 */
export function map2List(map) {
  return _.map(map, (value, key) => _.extend({}, value, { key }));
}

/**
 * [ {key:"a", x: 1}, ... ] --> { a: {x: 1}, ... }
 *
 * @param list
 */
export function list2Map(list, keyProp = 'key') {
  return _.chain(list).filter(keyProp).keyBy(keyProp).value();
}

/**
 * Serialize parameters map to query string using JSON encoding for values
 * @param params
 * @returns {string}
 */
export function serializeQueryString(params, json = true) {
  return _.map(params, (v, k) =>
    v !== undefined
      ? encodeURIComponent(k) +
        '=' +
        encodeURIComponent(json ? JSON.stringify(v) : v)
      : ''
  ).join('&');
}

export function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error(`JSON error in "${str}"`, e);
    return null;
  }
}

export function parseQueryString(str, json = true) {
  return _.fromPairs(
    (str[0] === '?' ? str.slice(1) : str)
      .split('&')
      .map((kv) => kv.split('='))
      .filter((pair) => pair.length === 2)
      .map(([k, v]) => [
        decodeURIComponent(k),
        json ? safeJsonParse(decodeURIComponent(v)) : decodeURIComponent(v),
      ])
  );
}

/**
 * Keep it simple and not too restrictive
 */
export function isValidEmail(str) {
  return str && /(.+)@[A-Z0-9-]{1,}\.[A-Z0-9-]{2,}/i.test(str);
}

// TODO HOC bindStateValue ?
/**
 * Bind a component with value and onChange prop to a parent's state
 * Replaces the classic :
 *    value={this.state.foo}
 *    onChange={value => this.setState({foo: value})}
 */
export function bindStateValue(WrappedComponent, parent, stateName) {
  return function BindStateValueComponent(props) {
    return (
      <WrappedComponent
        value={(parent.state || {})[stateName]}
        onChange={(value) => parent.setState({ [stateName]: value })}
        {...props}
      />
    );
  };
}

export function getRandomId(l = 2) {
  try {
    return btoa(window.crypto.getRandomValues(new Uint32Array(l)));
  } catch (e) {
    let s = '';
    while (--l >= 0) s += ~~(1000000000 * Math.random());
    return s;
  }
}

export function getFullUrl(base, url) {
  if (!/^https?:\/\//i.test(url)) return base + url;
  return url;
}

/**
 * Makes a GET query string from a parameter dict.
 * { a: 1 } => "?a=1"
 * Values are URL-encoded but not keys.
 *
 * @param parameter dict.
 */
export const buildQueryString = (params) =>
  Object.keys(params)
    .filter((k) => typeof params[k] !== 'undefined')
    .map((k) => k + '=' + encodeURIComponent(JSON.stringify(params[k])))
    .join('&');

export const NBSP = '\u202F';

// c/c server
// equivalent to escape-string-regexp
const regexEsc = (str) => str.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

/**
 *
 * @param str
 * @param replacementMap
 * @param caseInsensitive   C.I. search - replacementMap keys MUST BE lowercase
 * @returns {string|*|void}
 */
export function multiReplace(str, replacementMap, caseInsensitive = false) {
  var re = new RegExp(
    _.keys(replacementMap).map(regexEsc).join('|'),
    caseInsensitive ? 'gi' : 'g'
  );

  return str.replace(re, function (matched) {
    return caseInsensitive
      ? replacementMap[matched.toLowerCase()]
      : replacementMap[matched];
  });
}

/**
 * Replace "[[key]]" by value in str for { key: value, ... } in fields.
 * Similar to multireplace enclosing searched terms with [[  ]].
 * Case insensitive.
 *
 * @param str
 * @param fields
 * @returns {string|*|void}
 */
export function replaceFields(str, fields) {
  if (!str) return str;
  return multiReplace(
    str,
    _.mapKeys(fields, (v, k) => '[[' + k.toLowerCase() + ']]'),
    true
  );
}

type PrettyPriceOptions = {|
  forceDigits: boolean,
  currencyDisplay: string,
  forceInteger: boolean,
|};

export function prettyPrice(price, optionsParam: PrettyPriceOptions = {}) {
  const defaultOptions = {
    forceDigits: false,
    currencyDisplay: 'symbol',
    forceInteger: false,
  };
  const { currencyDisplay, forceDigits, forceInteger } = {
    ...defaultOptions,
    ...optionsParam,
  };
  const digits = forceInteger || (_.isInteger(price) && !forceDigits) ? 0 : 2;

  if (typeof Intl !== 'undefined') {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    return new Intl.NumberFormat(
      undefined,
      currencyDisplay !== 'none'
        ? {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: digits,
            maximumFractionDigits: forceInteger ? 0 : 5,
            currencyDisplay: currencyDisplay,
          }
        : {
            style: 'decimal',
            minimumFractionDigits: digits,
          }
    ).format(price);
  } else return price.toFixed(digits) + NBSP + '€';
}

export function now() {
  return moment().unix();
}

export function removeAccents(str) {
  return str
    ? str.normalize
      ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      : str.replace(/\W+/g, '')
    : '';
}

/**
 * Transform a string into nice URL-compatible part, e.g. "Un épatant contenu !" -> "un-epatant-contenu"
 */
export function urlize(str) {
  return str
    ? removeAccents(str.toLowerCase()) // then remove all non-alphanum characters:
        .replace(/^\W+|\W+$/g, '') // trim begin/end
        .replace(/\W+/g, '-') // replace others with "-"
    : '';
}

/**
 * Get first value if arrayOrValue is an array, otherwise return arrayOrValue
 * @param arrayOrValue (string|array)
 * @returns {*}
 */
export function getFirst(arrayOrValue) {
  return _.isArray(arrayOrValue) ? _.first(arrayOrValue) : arrayOrValue;
}

export function titleCase(str) {
  return str && str.replace
    ? str.replace(/(^|\s)\S/g, function (w) {
        return w.toUpperCase();
      })
    : str;
}

// Stupid test because DraftEditor return empty tags we can't remove
export const EMPTY_HTML_PATTERN = /^\s*<\w+>\s*(<br>|)\s*<\/\w+>\s*$/;
