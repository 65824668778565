// @flow
import type { Environment } from '@/types/environment';

import ENV from '@/constants/env';
import config from '@/utils/config';

export const isProd = (): boolean =>
  [ENV.PROD, ENV.PREPROD].includes(config.PUF_ENV);
export const isStaging = (): boolean => [ENV.STAGING].includes(config.PUF_ENV);
export const isDev = (): boolean => !isProd() && !isStaging();

const env = {
  isProd,
  isStaging,
  isDev,
};

export const getEnv = (): Environment => {
  if (isProd()) return ENV.PROD;
  if (isStaging()) return ENV.STAGING;
  return ENV.DEV;
};

export default env;
