import getConfig from 'next/config';
import ENV from '@/constants/env';

export const MAX_WIDTH_BY_COL_SIZE = [
  // see https://docs.google.com/spreadsheets/d/1mtc6Vg5f6ryWMz-0TZjibKCZ09gncaeJefXFjfyKW0w/
  0,
  720, 720, 840, 840, 720, 720, 840, 960, 1080, 1200, 1320, 1440,
];

//  break1240	break1080	break840	break720
export const COLL_RESPONSIVE_SIZE = [
  // see https://docs.google.com/spreadsheets/d/1mtc6Vg5f6ryWMz-0TZjibKCZ09gncaeJefXFjfyKW0w/
  {},
  { 1240: 2, 1080: 3, 720: 12 },
  { 1080: 3, 720: 12 },
  { 1080: 6, 840: 12 },
  { 840: 12 },
  { 1240: 6, 720: 12 },
  { 720: 12 },
  { 1240: 6, 720: 12 },
  { 840: 12 },
  { 1080: 8, 840: 12 },
  { 1080: 9, 720: 12 },
  { 1240: 10, 1080: 9, 720: 12 },
  {},
];

export const RETRACTED_HEADER_HEIGHT = 0;
export const PAGES_WITH_NO_POPUP = ['/register', '/login', '/registerPromo'];

export const ALLOW_SLIDER_LAZYLOADING_IMAGES = true; // new

let config;

//https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
const { publicRuntimeConfig = {} } = getConfig() || {}; // https://github.com/vercel/next.js/issues/4024#issuecomment-377837421
const PUF_ENV = publicRuntimeConfig.PUF_ENV;
const NODE_ENV = publicRuntimeConfig.NODE_ENV;
const GIT_TAG = publicRuntimeConfig.GIT_TAG;
const GIT_COMMIT = publicRuntimeConfig.GIT_COMMIT;
const GIT_BRANCH = publicRuntimeConfig.GIT_BRANCH;
const PUF_SERVER_URL = publicRuntimeConfig.PUF_SERVER_URL;
const STATIC_IMAGES_URL = publicRuntimeConfig.STATIC_IMAGES_URL;
const STATIC_ROOT_URL = publicRuntimeConfig.STATIC_ROOT_URL;

//console.debug("NODE_ENV", getConfig().publicRuntimeConfig.NODE_ENV);

if (PUF_ENV === ENV.PROD) {
  config = {
    API_URL: PUF_SERVER_URL || '/api',
    SERVER_API_URL: PUF_SERVER_URL || 'http://127.0.0.1:8001', // localhost server prod
    SOCKET_IO_SUBPATH: '/api',
    BASE_URL: '',
  };
} else if (PUF_ENV === ENV.PREPROD) {
  config = {
    API_URL: PUF_SERVER_URL || '/api',
    SERVER_API_URL: PUF_SERVER_URL || 'http://127.0.0.1:8002', // localhost server preprod
    SOCKET_IO_SUBPATH: '/api',
    BASE_URL: '',
  };
} else if (PUF_ENV === 'dev_on_preprod') {
  config = {
    API_URL: PUF_SERVER_URL || 'https://preprodweb1.wpvideoserver.com/api',
    SOCKET_IO_SUBPATH: '/api',
    BASE_URL: '',
  };
} else if (PUF_ENV === 'local_on_prod') {
  config = {
    API_URL: PUF_SERVER_URL || 'https://web1.wpvideoserver.com/api',
    SOCKET_IO_SUBPATH: '/api',
    BASE_URL: '',
  };
} else if (PUF_ENV === 'local') {
  config = {
    API_URL: 'https://local.fractal-it.fr:8443',
    BASE_URL: '',
  };
} else if (PUF_ENV === ENV.STAGING) {
  // mica.media devserver
  config = {
    API_URL: PUF_SERVER_URL || '/api',
    SERVER_API_URL: PUF_SERVER_URL || '/api', // to test
    BASE_URL: '',
  };
} else {
  // dev / devserver
  const STAGING_API_URL = 'https://staging-admin.mica.media/api'; // To test against staging server

  config = {
    API_URL: PUF_SERVER_URL || STAGING_API_URL,
    SERVER_API_URL: PUF_SERVER_URL || STAGING_API_URL, // to test
    BASE_URL: '',
  };
}

config = {
  ...config,
  PUF_ENV,
  NODE_ENV,
  GIT_TAG,
  GIT_BRANCH,
  GIT_COMMIT,
  STATIC_IMAGES_URL,
  STATIC_ROOT_URL,
};

export default config;

type GetApiUrlOptions = {|
  serverMode: boolean,
|};
export const getApiUrl = (options: GetApiUrlOptions) => {
  const { serverMode } = options || { serverMode: false };
  if (PUF_SERVER_URL) return PUF_SERVER_URL;
  if (serverMode) return config.SERVER_API_URL;
  return config.API_URL;
};
