// @flow
export const PROD = 'prod';
export const PREPROD = 'preprod';
export const STAGING = 'staging';
export const DEV = 'dev';

const ENV = {
  PROD,
  PREPROD,
  STAGING,
  DEV,
};

export default ENV;
