// @flow
import _ from 'lodash';
import request from 'superagent';
import config, { getApiUrl } from './config';
import { buildQueryString } from './misc';
import { injectEnvironment } from '@/helpers/request';

var requestMap = {};

type Method = 'get' | 'post' | 'put' | 'patch' | 'del';
type Channel = string;
type Params = Object;
type Body = Object;

class API {
  static URL: string = getApiUrl();
  static COOKIES: string;

  static setServerMode(cookiesToForward: string) {
    if (config.SERVER_API_URL) API.URL = config.SERVER_API_URL;
    if (cookiesToForward) API.COOKIES = cookiesToForward;
    // TODO disable gzip
  }

  /**
   * Make an HTTP request to the API
   *
   * @param method    HTTP Method, e.g. "get", "post" etc.
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise<any>}
   * @private
   */
  static async _request<T>(
    method: Method,
    path: string,
    baseParams?: Params,
    body?: Body,
    channel?: Channel
  ): Promise<T> {
    const params = injectEnvironment(baseParams);
    let url =
      API.URL + '/' + path + (params ? '?' + buildQueryString(params) : '');

    // Note: if error = UNABLE_TO_VERIFY_LEAF_SIGNATURE this is probably because the cert full chain is missing
    // try this on the server:
    // https://git.coolaj86.com/coolaj86/ssl-root-cas.js#important-try-this-first
    // otherwise *temporarily* you can use: process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';

    return new Promise((resolve, reject) => {
      if (channel) API.abort(channel);

      console.debug('API> --', method, url);

      let chain = request[method](url, body).withCredentials();

      if (API.COOKIES) chain.set('cookie', API.COOKIES);

      chain
        .then(
          (response) => {
            if (channel) delete requestMap[channel];
            if (!response.body || !('result' in response.body)) {
              reject(response.body && response.body.error);
            } else {
              let b = response.body;
              if (_.isObject(b.result)) {
                if (_.isInteger(b.count)) b.result.count = b.count; // TODO _count, _offset... instead
                if (params && params.offset) b.result.offset = params.offset;
                if (params && params.limit) b.result.limit = params.limit;
              }

              resolve(b.result);
            }
          },
          (err) => {
            console.log({ err });
            if (err && err.code === 'ABORTED') {
              console.debug('Api> Aborted', url);
              // TODO if aborted by new request, return new promise
              reject({
                code: 'ABORTED',
                message: 'Opération annulée (API)',
              });
            } else {
              console.warn(
                'Api> Error',
                err && err.code,
                (err && err.message) || err.status || err,
                '--',
                url
              );
              const error = _.get(
                err,
                'response.body.error',
                _.get(err, 'response.body', {})
              ); // TODO check if we have the 2 cases
              reject({
                code: error.code || err.status,
                message: error.message || err.toString(),
              });
            }
          }
        )
        .catch((e) =>
          console.error('Exception in API response handling', method, url, e)
        ); // useless?

      if (channel && chain) requestMap[channel] = chain; //(chain.xhr || chain.req);
    });
  }

  static abort(channel: Channel): boolean {
    if (requestMap[channel]) {
      console.debug('API> abort ', channel);
      requestMap[channel].abort();
      delete requestMap[channel];
      return true;
    }
    return false;
  }

  /**
   * Make a GET request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  static get<T>(path: string, params?: Params, channel?: Channel): Promise<T> {
    return API._request<T>('get', path, params, null, channel);
  }

  /**
   * Make a POST request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  static post<T>(path: string, body?: Body, channel?: Channel): Promise<T> {
    return API._request<T>('post', path, null, body, channel);
  }

  /**
   * Make a PATCH request to the API (not used)
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  static patch<T>(path: string, body?: Body, channel?: Channel): Promise<T> {
    return API._request<T>('patch', path, null, body, channel);
  }

  /**
   * Make a PUT request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  static put<T>(path: string, body?: Body, params?: Params): Promise<T> {
    return API._request<T>('put', path, params, body);
  }

  /**
   * Make a DELETE request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  static del<T>(path: string, params?: Params, channel?: Channel): Promise<T> {
    return API._request<T>('del', path, params, null, channel);
  }
}

export default API;
