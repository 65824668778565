// @flow

import config from '@/utils/config';
import { getEnv } from './env';

export function injectEnvironment(obj: Object): Object {
  const newObj = {
    ...obj,
    frontVersion: config.GIT_TAG,
    frontPUFEnv: getEnv().toUpperCase(),
  };
  return newObj;
}
